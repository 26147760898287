// import { loginHref, logoutHref } from "../../constants"
import {
  clearCredentials,
  Credentials,
  isLoggedIn,
  saveCredentials,
  validateCredentials,
} from "./credentials"
import { interruptClick, parseCookies, retry } from "./lib"

function saveAndRemoveCookies() {
  console.log('inside save n remove')
  
  const credentials: Credentials = parseCookies(document.cookie) as any;

  if (!validateCredentials(credentials)) {
    return
  }

  saveCredentials(credentials)

  // Object.keys(credentials).forEach(
  //   (key) => (document.cookie = `${key}=;expires=${new Date(0).toUTCString()}`),
  // )

  // location.reload()
  const server = process.env.REACT_APP_STAGE === "production" ? "https://hyperdart.com" : "https://dev.hyperdart.com";
  console.log('server',server, process.env.REACT_APP_STAGE)
  window.location.href=`${server}/partner-portal/token`
}



export interface InitOptions {
  loginButton: string
  logoutButton: string
  updateUsageInfo: () => void
}

//
// By default the login button opens a form that asks the user to submit credentials.
// We replace this behaviour and instead redirect to the route that handles OAuth.
//

export function init(options:InitOptions) {

  saveAndRemoveCookies();
  

  const {  updateUsageInfo,logoutButton ,loginButton} = options;

  document.addEventListener("click", () => retry(updateUsageInfo,10))
  
  retry(updateUsageInfo,20)
}
