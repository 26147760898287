import { clearCredentials, copyToClipboard, getUsageInfo, init, isLoggedIn } from "./plugin";


const loginButtonSelector = `[data-testid="header--button-login"]`
const logoutButtonSelector = `[data-testid="header--button-logout"]`
const tabSelector = `[data-testid="tab-content"]`
const logoutDialogButton=`[data-testid="logOutDialogIcon"]`
const helpCardSelector = `[data-testid="help-card"]`

function HideLogoutButton() {
  const logoutButton = document.querySelector(logoutDialogButton)

  if (logoutButton) {
    (logoutButton as HTMLElement).style.display = "none"
  }
}
function updateUsageInfo(): void {
  HideLogoutButton()
  console.log("Inside updateUsageInfo function"); 
  const loggedIn = isLoggedIn()

  if (!loggedIn) {
    clearCredentials();
    location.href=`${process.env.HYPERDART_SERVER_ADDRESS}/hd/login`
  }else{
    const tabs = document.querySelectorAll(tabSelector)
    if (!tabs) return
  
    const usageInfoLines = getUsageInfo().split("\n").reverse()
  
    tabs.forEach((tab) => {
      const alreadyReplaced = tab.getAttribute("replaced") === "true"
      if (alreadyReplaced) return
  
      const commands = Array.from<HTMLElement>(tab.querySelectorAll("button"))
        .map((node) => node.parentElement!)
        .filter((node) => !!node.innerText.match(/^(npm|pnpm|yarn)/));
      if (!commands.length) return
  
      usageInfoLines.forEach((info) => {
        const cloned = commands[0].cloneNode(true) as HTMLElement
        const textEl = cloned.querySelector("span")!
        textEl.innerText = info
  
        const copyEl = cloned.querySelector("button")!
        copyEl.style.visibility = loggedIn ? "visible" : "hidden"
        copyEl.onclick = (e) => {
          e.preventDefault()
          e.stopPropagation()
          copyToClipboard(info)
        }
  
        commands[0].parentElement!.appendChild(cloned)
        tab.setAttribute("replaced", "true")
      })
  
      // Remove commands that don't work with oauth
      commands.forEach((node) => {
        if (
          node.innerText.includes("adduser") ||
          node.innerText.includes("set password") ||
          node.innerText.includes("set registry")
        ) {
          node.parentElement!.removeChild(node)
          tab.setAttribute("replaced", "true")
        }
      })
    })
  
    const helpCard= document.querySelector(helpCardSelector);
    if (helpCard) {
      helpCard.innerHTML = "";
      const newContent = document.createElement("div");
      newContent.style.padding = "20px";
  
      const newContentHeading = document.createElement("div");
      newContentHeading.innerHTML=`Publish Your First Package:`
      newContentHeading.style.fontSize='20px'
      newContentHeading.style.fontWeight='600'
      newContentHeading.style.padding = "20px 20px 0 20px";
    
  const messageEl = document.createElement("div");
usageInfoLines.forEach((item, index) => {
  const copyEl = document.createElement("span");
  copyEl.style.cursor = "pointer";
  copyEl.style.float = "right";
  copyEl.innerHTML = `
    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M272 0H396.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V336c0 26.5-21.5 48-48 48H272c-26.5 0-48-21.5-48-48V48c0-26.5 21.5-48 48-48zM48 128H192v64H64V448H256V416h64v48c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48z"/></svg>
  `;
  copyEl.onclick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    copyToClipboard(item);
 
    copyEl.innerHTML=''
    messageEl.textContent = "Copied To Clipboard!";
    messageEl.style.fontSize="14px"
    messageEl.style.color="#F05F40"
    messageEl.style.fontWeight="bold"

    copyEl.appendChild(messageEl);
    

    setTimeout(() => {
      copyEl.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M272 0H396.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V336c0 26.5-21.5 48-48 48H272c-26.5 0-48-21.5-48-48V48c0-26.5 21.5-48 48-48zM48 128H192v64H64V448H256V416h64v48c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48z"/></svg>
    `;
      copyEl.removeChild(messageEl);

    }, 2000);

  };

  const itemDiv = document.createElement("div");
  itemDiv.style.wordWrap = "break-word";
  itemDiv.style.marginBottom = "20px";
  itemDiv.textContent = `${index + 1}. ${item}`;

  itemDiv.appendChild(copyEl);

  newContent.appendChild(itemDiv);
});

      helpCard.appendChild(newContentHeading)
      helpCard.appendChild(newContent);
    }
  }
 

  
}

init({
  loginButton: loginButtonSelector,
  logoutButton: logoutButtonSelector,
  updateUsageInfo,
})

